import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");
const image1 = require("../../../assets/images/market_ui/slack/img_1.png");
const image2 = require("../../../assets/images/market_ui/slack/img_2.png");
const image3 = require("../../../assets/images/market_ui/slack/img_3.png");
const image4 = require("../../../assets/images/market_ui/slack/img_4.png");

const BlogHeader = require("../../../assets/img/blogs/header_blog_covid_19.png.webp");
const Section1 = require("../../../assets/img/blogs/chatbot_best_1.png");
const Section2 = require("../../../assets/img/blogs/chatbot_best_2.png");
const Section3 = require("../../../assets/img/blogs/chatbot_best_3.jpeg");
const Section4 = require("../../../assets/img/blogs/Blog_Chatbot Best Practices_1.jpg");
const Section5 = require("../../../assets/img/blogs/chatbot_best_5.png");
const Section6 = require("../../../assets/img/blogs/chatbot_best_6.png");
const Section7 = require("../../../assets/img/blogs/chatbot_best_7.png");
const Section8 = require("../../../assets/img/blogs/chatbot_best_8.png");
const SectionLast = require("../../../assets/img/blogs/chatbot_best_final.gif");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Reinventing the role of HR during the COVID-19 Pandemic — Business Continuity Planning, Chatbots, and Whatnot"
        description="With HR chatbots to take on the load of requests and inquiries during the pandemic, HR personnel can focus better on business continuity. "
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Reinventing the role of HR during the COVID-19
                            Pandemic — Business Continuity Planning, Chatbots,
                            and Whatnot
                          </h1>
                        </div>
                      </div>
                      <img src={BlogHeader} />
                      <div className=" mt-5 market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          To quote Dave Ulrich,
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Navigating paradox accepts and heightens disagreements
                          that enable organizations to change and evolve.”
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          This quote makes sense now more than ever. HR
                          professionals need to become{" "}
                          <a href="https://www.linkedin.com/pulse/10-skills-hr-pros-need-paradox-minded-dave-ulrich/">
                            Paradox Navigators{" "}
                          </a>
                          if they want their businesses to thrive amongst the
                          COVID-19{" "}
                          <a href="https://www.moodysanalytics.com/webinars-on-demand/2020/covid19-global-recession">
                            global recession
                          </a>{" "}
                          panic. A gargantuan task I know, but all the more
                          doable.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          When anyone hears the word HR, two things pop up on
                          their mind. Hiring and Workplace Policies. Because
                          hey, HRs are the ones who are going to hire anyone
                          around here. Psst. Except for{" "}
                          <a href="https://workativ.com/">HR Chatbots,</a> but
                          more on that later 😉
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Also, HRs are the ones who ensure that the
                          relationship between a business and its employees as
                          well as its customers are well-oiled and frictionless.
                          But now, no thanks to the coronavirus outbreak,
                          everything’s in limbo and HRs have to keep people
                          up-to-date, be reassuring, and build trust. Whew —
                          that’s a lot of work.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          If you are an HR or someone who’s looking to help your
                          HR personnel with some pointers, you’re in the right
                          place. Let’s take a look at some of the things HRs
                          have to cover for proper Business Continuity Planning
                          (BCP).{" "}
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h2 className="font-section-sub-header-small-bold">
                          Points HRs should cover before coming up with a BCP
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          HR personnel should focus on drafting the BCP around
                          keeping employees productive, motivated, engaged, and
                          connected. Because, when employees are taken well care
                          of, they take good care of your business, for you.
                          Following are some of the pointers for an HR to ponder
                          over to ensure business continuity:
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          1. Handling Absenteeism with Ease
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          HRs should see to that employees within the company
                          are cross-trained to handle critical/key
                          roles/responsibilities in case people in that role
                          report in sick. They can also hire over capacity for
                          some of those critical capabilities to guarantee that
                          we have the skillsets on hand. HRs should perform a
                          proper analysis of their business to check whether
                          their organization can operate with 25% or greater
                          absenteeism/furlough.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          2. Enabling Employees to stay connected
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          HR personnel should ensure that proper infrastructure
                          is in place for providing manager resources for
                          leaders to help keep their remote teams focused and
                          connected and have encouraged daily touchpoint
                          meetings, virtual coffee breaks, and more frequent
                          organizational meetings.
                        </p>

                        <h6 className="font-section-sub-header-small-bold">
                          3. Health and wellness benefits are going to be a
                          game-changer
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          While planning out business continuity, HRs should be
                          focussed on structuring it around the fact that
                          employees would be dealing with health-related
                          challenges or caring for their kids or other
                          dependents during this crisis. HR personnel would want
                          to rethink their company’s approach to employees’
                          health care by investing in approaches that are more
                          forward-thinking than the standard duty of care. This
                          can easily be done by looking at everything through
                          the employees’ lens, taking into account that the
                          health and well-being of employees and their families
                          are first and foremost.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          HRs can even contract with physicians in cities where
                          their workers are based to ensure that a doctor is
                          quickly available to provide care, as well as advocate
                          for them if they become ill and need to visit a
                          hospital. HRs should see to that the company covers
                          any testing and treatment costs associated with
                          COVID-19 for employees and their families as well.
                        </p>

                        <h6 className="font-section-sub-header-small-bold">
                          4. Providing Solace to employees at large
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          This will be a humongous task for HR professionals.
                          Arranging for one-on-one calls with employees, keeping
                          them up-to-date as well as being reassuring and
                          building trust, all these go a long way for the
                          business. When employees feel that they are understood
                          during remote work, their productivity skyrockets and
                          they do their best to drive the company towards glory.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          If HR personnel were to consider the above points,
                          they can easily draft an effective BCP. There is
                          another way to make this easy on HRs. And that is…
                        </p>
                      </div>

                      <div>
                        <h6 className="font-section-sub-header-small-bold">
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                            HR Chatbots
                          </a>{" "}
                          — How they can make a huge difference
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Businesses can make use of Platform-As-A-Service
                          providers (PaaS) to build AI-powered contextual
                          chatbots with{" "}
                          <a href="https://workativ.com/assistant/workflow-automation">
                            automated workflows
                          </a>{" "}
                          that address both an HR and an employees’ IT query and
                          solves the query real-time for them, on their
                          business’ Slack or Microsoft Teams workspace. Some of
                          the key areas where HR Chatbots can be used include:
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          1. Unbiased Hiring
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Humans are subjective beings who make decisions based
                          on their field of experience and even the most skilled
                          and learned individuals are subject to unconscious
                          biases. These prejudices also creep into the workplace
                          and can compromise the hiring process. While there are
                          safeguards to ensure that discrimination in the
                          recruitment process is eliminated, the politically and
                          socially charged times we live in mean that
                          maintaining complete objectivity is often impossible.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          HR chatbots can help eradicate human bias in the
                          hiring process and take objective decisions based on
                          the relevance of the applicant’s skill and experience.
                          Chatbots can ensure that only relevant candidate
                          attributes are taken into account while determining if
                          he/she is the right fit for the role. Recently, there
                          have been concerns regarding humans passing their
                          subjective biases and views to machines and bots.
                          However, the recognition of this will only result in a
                          renewed focus on developing and training technology in
                          an unbiased and fair manner.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          2. Onboarding/Offboarding Employees
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Companies can connect their human resources software
                          like{" "}
                          <a href="https://blog.workativ.com/integrate-bamboohr-with-workativ-and-simplify-hrms/">
                            BambooHR
                          </a>{" "}
                          or{" "}
                          <a href="https://blog.workativ.com/zoho-people-integration-and-workflow-automation-workativ/">
                            Zoho People
                          </a>{" "}
                          with HR chatbots, allowing the chatbots to
                          onboard/offboard employees in an instant. Whether it’d
                          be
                          <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                            {" "}
                            adding a new employee’s details{" "}
                          </a>
                          into the database, updating an existing employee’s
                          details, getting the details of all employees or
                          removing an employee’s details from the database, HRs
                          can do this and more in the blink of an eye with HR
                          chatbots at their disposal on their Slack or
                          <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot">
                            {" "}
                            Microsoft Teams
                          </a>{" "}
                          workspace.
                        </p>

                        <h6 className="font-section-sub-header-small-bold">
                          3. HR Process Automation
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          HR processes like{" "}
                          <a href="https://workativ.com/use-cases/leave-management-automation">
                            applying for leaves
                          </a>
                          , checking leave balance, getting the list of
                          holidays, requesting time-offs shouldn’t be much of a
                          burden on employees. Employees don’t have to go
                          through the hassle of logging in to a static HRMS
                          portal where they have to navigate through endless
                          convoluted menus to actually find what they are
                          looking for, be it getting info on time-off policies,
                          benefit plans, or downloading payslips. Businesses can
                          automate all these tasks with HR chatbots so that
                          employees can do all these and more on-the-go, on
                          their business’ collaboration hub like Slack or
                          Microsoft Teams.
                        </p>

                        <h6 className="font-section-sub-header-small-bold">
                          4. Easy Access to Work-from-home Guidelines and other
                          up-to-date information
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          HR chatbots also allow for making telecommuting
                          guidelines and other up-to-date information easily
                          accessible to employees on their business’ chat hub.
                          This gives employees a sigh of relief as they are able
                          to verify that they are following remote working
                          policies and also able to follow up on the daily
                          status of how the coronavirus pandemic is unraveling.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          5. Getting employee feedback occasionally to check on
                          their morale{" "}
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          HRs can use a{" "}
                          <a href="https://workativ.com/">no-code platform</a>{" "}
                          to{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/hr-virtual-assistant-guide">
                            build HR chatbots themselves quickly
                          </a>{" "}
                          and add it to their business’ workspace. HRs can then
                          set up conversations for the chatbot so that it asks
                          employees for their feedback on remote work and what
                          all they felt can be improved that front.{" "}
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          6. Automating questions to check COVID-19 symptoms for
                          employees
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          HRs can consult with physicians can set up COVID-19
                          screening questions for employees on HR chatbots to
                          enable employees to check if they are affected by the
                          virus and to give them a sense of relief. HRs can also
                          set up a COVID-19 knowledge base that employees can
                          access through the chatbot.{" "}
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          In addition to the screener and knowledge base, HRs
                          can set up a navigator tool that directs employees to
                          custom endpoints such as virtual visits, appointment
                          scheduling, a live chat agent, or coronavirus
                          educational materials.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          When the going gets tough, the tough get going
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          While previous tactics and strategies will continue to
                          be a part of HR managers’ toolkit to efficiently
                          manage employees, businesses all over the world will
                          see a bigger shift taking place in the wake of the
                          coronavirus that will fundamentally change the role of
                          HR professionals.{" "}
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          The role of HR professionals will see a dramatic
                          change in the foreseeable future via the evolution of
                          the health care system with the private-sector and the
                          public-sector communities working to fix what seems to
                          be a relatively broken system.{" "}
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          HR personnel need to embrace the changes thrown at
                          them to better navigate their business and employees
                          through these tough times.{" "}
                        </p>
                        <ExistingBlogCta
                          ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                          ButtonText="Book a Demo"
                          isColor="black"
                          backgroundImage={cta_1}
                          mobileBackgroundImage={cta_1_mob}
                        />
                        <div className="most_popular_links">
                          <div className="most_popular_header font-section-sub-header-bold">
                            Related Articles
                          </div>
                          <div className="most_popular_ul">
                            <ul
                              className="section__ul"
                              style={{ width: "100%", float: "left" }}
                            >
                              <li>
                                <a href="https://workativ.com/conversational-ai-platform/blog/hr-help-desk-guide">
                                  The ultimate guide - 2023 HR HELP DESK
                                </a>
                              </li>
                              <li>
                                <a href="https://workativ.com/conversational-ai-platform/blog/hr-service-management-guide">
                                  {" "}
                                  A Complete Guide - 2023 HR Service Management
                                </a>
                              </li>
                              <li>
                                <a href="https://workativ.com/conversational-ai-platform/blog/hr-digital-transformation-guide-2023">
                                  The ultimate guide - 2023 HR DIGITAL
                                  TRANSFORMATION
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="button_last">
                          <button>
                            <a href="https://blog.workativ.com/category/conversational-ai">
                              Conversational AI
                            </a>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="https://assistant.workativ.com/authentication/u/direct-signup">
                        <img src={topImage} alt="slack chatbot" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
